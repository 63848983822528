import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import OverflowHidden from "../components/overflow-hidden";

import AboveFold from "../components/home/above-fold";

import Platforms from "../components/home/platforms";

import Features from "../components/home/featuresBrief";

import aboveFoldStyles from "../components/home/above-fold-graph.module.css";
import styles from "../components/home/platforms-margin.module.css";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query QuestradeIndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const QuestradePage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <OverflowHidden>
        <AboveFold
          title="Better tracking for your investments"
          subtitle="Track what matters to you in a single dashboard view. Learn everything you want to know about your Questrade portfolio’s growth, fees, dividend payments and more."
          subtext="FREE FOR QUESTRADE CLIENTS"
          customStyles={aboveFoldStyles}
        />
        <Platforms
          title="Transform your investing"
          tileOrder={[0, 2, 1]}
          primaryStyle={styles.platforms}
          cta={false}
        />

        <Features />
      </OverflowHidden>
    </Layout>
  );
};

export default QuestradePage;
